header {
    background-color: var(--beige);
    box-shadow: 0 4px 8px var(--black-t2);
    height: 52px;
    min-width: 320px;
    position: fixed;
    user-select: none;
    width: 100%;
    z-index: 9999;
}

.toggle {
    align-items: center;
    background-color: var(--beige);
    cursor: pointer;
    display: flex;
    font-size: var(--fs3);
    height: 52px;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    width: 100%;
    z-index: 9998;
}

nav {
    background-color: var(--beige);
    display: flex;
    flex-direction: column;
    position: relative;
    transform: translateY(-100%);
    transition: transform .3s;
    z-index: 9997;
}

.navOpen {
    background-color: transparent;
    box-shadow: none;
    height: auto;

    .toggle {
        font-size: 6.5rem;
    }

    nav {
        transform: translateY(0);
    }

    .overlay {
        opacity: 1;
        pointer-events: all;
    }
}

.overlay {
    background: var(--black-t);
    inset: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    transition: opacity .3s;
    z-index: 9996;
}

.item {
    align-items: center;
    display: flex;
    font-size: var(--fsP);
    flex: 1;
    justify-content: center;
    padding: 16px;
    position: relative;
    text-align: center;
    white-space: nowrap;

    &::after {
        background-color: var(--green-3);
        content: '';
        position: absolute;
        bottom: 8px;
        left: 50%;
        transform: translateX(-50%);
        transition: .3s width;
        height: 1px;
        width: 0;
    }

    &:hover::after,
    &:focus::after {
        width: 16px;
    }
}

@media (min-width: 880px) {
    .toggle {
        display: none;
    }

    nav {
        flex-direction: row;
        transform: translateY(0);
    }
}