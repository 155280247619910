.grid {
    display: grid;
    grid-template-columns: 0 1fr 0;
    place-items: center;
}

@media (min-width: 640px) {
    .grid {
        grid-template-columns: 1fr 2fr 1fr;
    }
}

.chevron {
    left: 0;
    position: relative;
    transition: .3s left;
}

a:hover + .chevron,
a:focus + .chevron {
    left: 2px;
}

.location {
    margin-top: 48px;
}

.flowersL,
.flowersR {
    rotate: 90deg;
    user-select: none;
    width: 100%;
}

.flowersL {
    scale: 1 -1;
}