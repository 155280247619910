.guest {
    margin-bottom: 48px;
}

.course {
    margin-bottom: 24px;
}

.dishName {
    margin-bottom: 16px;
    width: 100%;

    i {
        font-style: italic;
    }
}

.hr {
    background-color: var(--grey-3);
    height: 3px;
    margin: 64px auto 0;
    width: 64px;
}