:global(main) {
    position: relative;
}

.rsvpLoading {
    background-color: var(--white-t);
    background-image: url(../../img/loadingdark.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80px;
    inset: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    transition: opacity .3s;
}

.rsvpLoadingShow {
    opacity: 1;
    pointer-events: all;
}