.hr {
    background-color: var(--grey-3);
    height: 3px;
    margin: 48px auto 0;
    width: 64px;
}

.flowers {
    margin: 0 auto;
    max-width: 120px;
    user-select: none;
    width: 60%;
}

@media (min-width: 640px) {
    .flowers {
        max-width: 240px;
    }
}