main {
    padding-top: 56px;
}

section {
    padding: 16px;
    text-align: center;
}

@media (min-width: 640px) {
    section {
        padding: 48px;
    }
}

section:nth-child(even) {
    background-color: var(--beige);
}

section:nth-child(odd) {
    background-color: var(--white);
}

.centre {
    background-color: inherit;
    margin: 0 auto;
    max-width: 1024px;
}

.sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}