.event {
    background-color: inherit;
    margin-bottom: 80px;
    // position: relative;

    &:last-child {
        margin: 0;
    }
}

.eventDate {
    display: block;
    font-size: var(--fs2);
}

.eventPlace {
    display: block;
    font-size: var(--fs3);
}

.part {
    background-color: inherit;
    margin-bottom: 48px;
    padding-top: 24px;
    position: relative;

    &::before {
        background-color: var(--grey-3);
        content: '';
        height: 2px;
        left: 50%;
        max-width: 360px;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        width: 100%;
    }

    h4 {
        font-size: var(--fs4);
    }

    p {
        font-size: var(--fs3);
    }
}

.partIcon {
    background-color: inherit;
    display: block;
    left: 50%;
    padding: 2px;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%);
    width: 40px;
}

// .flowersLeft {
//     bottom: 0;
//     display: none;
//     left: 50%;
//     position: absolute;
//     rotate: 90deg;
//     transform: translateY(50%);
//     user-select: none;
//     width: 200px;
// }

// @media (min-width: 640px) {
//     .flowersLeft {
//         display: block;
//     }
// }