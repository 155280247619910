.dietaryRequirements {
    align-items: center;
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr auto;
}

.input {
    flex: 1;
    text-align: left;
}

.submitBtn {
    min-width: 96px;
}
