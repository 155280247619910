.button {
    background-color: transparent;
    border: 2px solid var(--grey-2);
    border-radius: 4px;
    color: var(--green-1);
    cursor: pointer;
    min-height: 44px;
    padding: 8px 16px;
    user-select: none;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
        background-color: var(--grey-1);
        border-color: transparent;
        color: var(--white);
    }

    &:disabled {
        cursor: not-allowed;
    }
}

.loading {
    background-image: url(../../img/loadinglight.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
}

.selected {
    background-color: var(--grey-2);
    color: var(--white);

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
        background-color: var(--grey-1);
    }
}