:root {
    --beige: #f2f2f2;
    --green-1: #1b3625;
    --green-2: #3f5746;
    --green-3: #5b7865;
    --grey-1: #3d4347;
    --grey-2: #6c767f;
    --grey-3: #979fa6;
    --red: #c00;
    --white: #fff;
    --white-t: rgba(255, 255, 255, .8);
    --black-t: rgba(0, 0, 0, .6);
    --black-t2: rgba(0, 0, 0, .1);
}