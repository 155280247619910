.theWeddingDay {
    padding-bottom: 0;
}

.top {
    padding-bottom: 144px;
    position: relative;
}

@media (min-width: 640px) {
    .top {
        margin-bottom: 32px;
    }
}

.flowersTop {
    margin: 0 auto 16px;
    max-width: 360px;
    user-select: none;
    width: 60%;
}

.flowersLeft,
.flowersRight {
    bottom: 0;
    max-width: 200px;
    position: absolute;
    rotate: 90deg;
    user-select: none;
    width: 35%;
}

.flowersLeft {
    left: 16px;
}

.flowersRight {
    scale: 1 -1;
    right: 16px;
}

.handj {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: var(--fs1);
    justify-content: center;

    span:nth-child(2) {
        color: var(--grey-3);
        font-family: 'champagne';
        font-size: var(--fsL);
        margin: -32px 0;
    }
}

@media (min-width: 640px) {
    .handj {
        flex-direction: row;
        gap: 24px;

        span:nth-child(2) {
            margin: 0;
        }
    }
}

.photo {
    padding: 0 16px;
    user-select: none;
    width: 100%;
}

@media (min-width: 640px) {
    .photo {
        margin: -32px auto 0;
        padding: 0 48px;
        width: 90%;
    }
}

.inviteyou {
    color: var(--grey-3);
    display: block;
    font-size: var(--fs3);
    margin-bottom: 8px;
}

.dohertyrowe {
    display: block;
    font-size: var(--fs1);
    margin-bottom: 48px;
}

@media (min-width: 640px) {
    .dohertyrowe {
        margin-bottom: 80px;
    }
}

.date {
    color: var(--green-2);
    display: block;
    font-size: var(--fs2);
    margin-bottom: 16px;
}

.address {
    display: block;
    font-size: var(--fs3);
    margin: 0 auto;
    max-width: 640px;
    padding: 16px;
    position: relative;

    p {
        margin-bottom: 8px;
    }

    &::before,
    &::after {
        border-color: var(--grey-3);
        border-style: solid;
        border-width: 0;
        content: '';
        height: 50%;
        position: absolute;
        transition: .6s height, .6s width;
        width: 45%;
    }

    &::before {
        border-left-width: 3px;
        border-top-width: 3px;
        left: 0;
        top: 0;
    }

    &::after {
        border-bottom-width: 3px;
        border-right-width: 3px;
        bottom: 0;
        right: 0;
    }

    &:hover::before,
    &:focus::before,
    &:hover::after,
    &:focus::after {
        height: 55%;
        width: 50%;
    }
}

@media (min-width: 640px) {
    .address {
        padding: 32px;
    }
}

.map {
    border: 0;
    height: 288px;
    user-select: none;
    width: 100%;
}