.form {
    display: flex;
    font-size: var(--fs4);
    gap: 8px;
    justify-content: center;
}

.invitationCode {
    -moz-appearance: textfield;
    letter-spacing: 8px;
    min-width: 176px;
    text-indent: 8px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.submitBtn {
    min-width: 96px;
}