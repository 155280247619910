.flowers {
    margin: 40px auto;
    max-width: 120px;
    user-select: none;
    width: 60%;
}

@media (min-width: 640px) {
    .flowers {
        max-width: 240px;
    }
}