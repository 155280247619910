.countdown {
    font-family: 'Hello';
    position: relative;
    z-index: 2;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
}

.column {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: 64px;
}

@media (min-width: 640px) {
    .column {
        min-width: 96px;
    }
}

.wedding-day {
    font-size: var(--fs1);
}

.number {
    font-size: var(--fs1);
    margin-bottom: -16px;
}

.period {
    color: var(--green-2);
    font-size: var(--fs3);
}