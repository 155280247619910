.guestRsvp {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
}

.guestName {
    font-size: var(--fs4);
    margin-bottom: 8px;
}

.guestRsvpButtons {
    display: flex;
    gap: 8px;
}

.guestRsvpButton {
    flex: 1;
}