.event {
    border-bottom: 2px solid var(--grey-3);
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 32px 0;

    &:last-of-type {
        border: 0;
    }
}

.noDivider {
    border: 0;
}

.column {
    flex: 1;
}

.h3,
.date,
.time,
.location {
    font-family: 'hello', cursive;
}

.h3 {
    font-size: var(--fs2);
}

.date,
.time,
.location {
    color: var(--green-2);
    font-size: var(--fs3);
    margin: 0;
}

.location > a::after {
    bottom: 4px;
}

.desc {
    margin-top: 16px;
}

.flowers {
    margin: 0 auto;
    max-width: 120px;
    user-select: none;
    width: 60%;
}

@media (min-width: 640px) {
    .event {
        flex-direction: row;
        gap: 64px;
    }

    .flowers {
        max-width: 240px;
    }
}