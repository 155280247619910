:root {
    font-size: 62.5%;
    --fsL: 10rem;
    --fs1: 5rem;
    --fsH2: 4rem;
    --fs2: 4rem;
    --fs3: 2.8rem;
    --fs4: 1.8rem;
    --fsP: 1.6rem;
    --fsS: 1.3rem;
    --fsXS: 1.2rem;
    --fsXXS: 1rem;
}

@media (min-width: 640px) {
    :root {
        font-size: 62.5%;
        --fsL: 18rem;
        --fs1: 8rem;
        --fsH2: 6rem;
        --fs2: 4rem;
        --fs3: 3rem;
        --fs4: 2rem;
        --fsP: 1.6rem;
        --fsS: 1.3rem;
        --fsXS: 1.2rem;
        --fsXXS: 1rem;
    }
}

@font-face {
    font-family: 'hello';
    src: url('../fonts/hello-webfont.woff2') format('woff2'),
         url('../fonts/hello-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'champagne';
    src: url('../fonts/champagne-webfont.woff2') format('woff2'),
         url('../fonts/champagne-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
    color: var(--green-1);
    font-family: 'Lora', serif;
    font-size: var(--fsP);
}

.cursive {
    font-family: 'hello', cursive;
}

h2 {
    font-size: var(--fsH2);
    font-family: 'hello', cursive;
    margin-bottom: 48px;
    padding-bottom: 8px;
    position: relative;

    &::after {
        background-color: var(--grey-3);
        content: '';
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        height: 3px;
        width: 64px;
    }
}

h3 {
    color: var(--green-2);
    font-size: var(--fs3);
    margin-bottom: 24px;
}

h4 {
    color: var(--green-2);
    font-size: var(--fs4);
    margin-bottom: 24px;
}

p {
    line-height: 1.3em;
    margin-bottom: 24px;
}

small {
    font-size: var(--fsS);
}

p > a,
span > a {
    position: relative;

    &::after {
        background-color: currentColor;
        bottom: 0;
        content: '';
        height: 1px;
        left: 50%;
        transform: translateX(-50%);
        transition: .3s width;
        width: 100%;
        position: absolute;
    }

    &:hover::after,
    &:focus::after {
        width: 16px;
    }
}

.error {
    color: var(--red);
}