.input {
    background: var(--white);
    border: 2px solid var(--grey-3);
    border-radius: 4px;
    min-height: 44px;
    padding: 8px;

    &:focus {
        border-color: var(--grey-1);
    }

    &:disabled {
        cursor: not-allowed;
        opacity: .4;
    }
}